<template>
  <div
    v-if="item.chapters.length > 0"
    class="col-md-4 col-sm-6 col-12 kb-search-content"
  >
    <!-- account setting card -->
    <div class="card">
      <div class="card-body">
        <!-- account setting header -->
        <h6 class="kb-title">
          <i
            data-feather="help-circle"
            class="font-medium-4 me-50"
          />
          <span>{{ item.title }}</span>
        </h6>

        <ul class="list-group list-group-circle mt-2">
          <li
            v-for="(chapter, index2) in item.chapters"
            :key="index2"
            class="list-group-item"
          >
            <a
              class="text-body"
              @click="$router.push({name: 'knowledge-base.show', params: { id: chapter.id }})"
            >{{ chapter.title }}</a>
            <template v-if="user.roles.includes('super-admin')">
              <span
                v-if="chapter.onlyAdmin == true"
                class="badge bg-light-info"
              >admin</span>
              <span
                v-if="chapter.onlyAdmin == false"
                class="badge bg-light-success"
              >public</span>
            </template>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- no result -->
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    item: { type: Object, required: true, default: () => {} },
  },
  computed: {
    ...mapGetters({
      user: 'auth/admin',
    }),
  },
}
</script>

<style>

</style>
